import * as API from "./Api";

export const getEmployeeList = () => {
  return API.get("/user");
};
export const getEmployee = (user_id) => {
  return API.get(`/user/${user_id}`);
};
export const addEmployee = (params) => {
  return API.post("/register", params);
};
export const updateEmployee = (user_id, params) => {
  return API.put(`/user/${user_id}`, params);
};
export const changePassword = (user_id, params) => {
  return API.put(`/user/${user_id}`, params);
};
