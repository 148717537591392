import { put, call, takeLatest } from "redux-saga/effects";
import * as types from "../actions/types";
import * as UserService from "../services/UserService";
import * as UserAction from "../actions/UserAction";
import { handleException } from "./Utils";

export default function* sagas() {
  function* getEmployeeList() {
    try {
      const res = yield call(UserService.getEmployeeList);
      if (res) {
        yield put(UserAction.getEmployeeListSuccess(res));
      } else {
        yield put(
          UserAction.getEmployeeListFail(res?.message || "Invalid request")
        );
      }
    } catch (error) {
      console.log(error);
      if (yield handleException(error)) {
        // To-do
      } else {
        yield put(
          UserAction.getEmployeeListFail(
            error?.response?.data?.message ||
              "Something went wrong, please try again."
          )
        );
      }
    }
  }
  function* getEmployee(action) {
    try {
      const { user_id } = action.payload || {};
      const res = yield call(UserService.getEmployee, user_id);
      if (res) {
        yield put(UserAction.getEmployeeSuccess(res));
      } else {
        yield put(
          UserAction.getEmployeeFail(res?.message || "Invalid request")
        );
      }
    } catch (error) {
      console.log(error);
      if (yield handleException(error)) {
        // To-do
      } else {
        yield put(
          UserAction.getEmployeeFail(
            error?.response?.data?.message ||
              "Something went wrong, please try again."
          )
        );
      }
    }
  }
  function* addEmployee(action) {
    try {
      const params = action.payload || {};
      const res = yield call(UserService.addEmployee, params);
      if (res) {
        yield put(
          UserAction.addEmployeeSuccess("Employee Detail is added successfully")
        );
      } else {
        yield put(
          UserAction.addEmployeeFail(res?.message || "Invalid request")
        );
      }
    } catch (error) {
      console.log(error);
      if (yield handleException(error)) {
        // To-do
      } else {
        yield put(
          UserAction.addEmployeeFail(
            error?.response?.data?.message ||
              "Something went wrong, please try again."
          )
        );
      }
    }
  }
  function* updateEmployee(action) {
    try {
      const { user_id, params } = action.payload || {};
      const res = yield call(UserService.updateEmployee, user_id, params);
      if (res) {
        yield put(
          UserAction.updateEmployeeSuccess(
            "Personal information is updated successfully"
          )
        );
      } else {
        yield put(
          UserAction.updateEmployeeFail(res?.message || "Invalid request")
        );
      }
    } catch (error) {
      console.log(error);
      if (yield handleException(error)) {
        // To-do
      } else {
        yield put(
          UserAction.updateEmployeeFail(
            error?.response?.data?.message ||
              "Something went wrong, please try again."
          )
        );
      }
    }
  }
  function* changePassword(action) {
    try {
      const { user_id, params } = action.payload || {};
      const res = yield call(UserService.changePassword, user_id, params);
      if (res) {
        yield put(
          UserAction.changePasswordSuccess(
            "Personal information is updated successfully"
          )
        );
      } else {
        yield put(
          UserAction.changePasswordFail(res?.message || "Invalid request")
        );
      }
    } catch (error) {
      console.log(error);
      if (yield handleException(error)) {
        // To-do
      } else {
        yield put(
          UserAction.changePasswordFail(
            error?.response?.data?.message ||
              "Something went wrong, please try again."
          )
        );
      }
    }
  }
  yield takeLatest(types.GET_EMPLOYEE_LIST_REQUEST, getEmployeeList);
  yield takeLatest(types.GET_EMPLOYEE_REQUEST, getEmployee);
  yield takeLatest(types.UPDATE_EMPLOYEE_REQUEST, updateEmployee);
  yield takeLatest(types.ADD_EMPLOYEE_REQUEST, addEmployee);
  yield takeLatest(types.CHANGE_PASSWORD_REQUEST, changePassword);
}
