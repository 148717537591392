import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './redux/store'
import App from "./App";
import "./css/App.scss";
import "react-bootstrap-typeahead/css/Typeahead.css";

const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
