import { toast } from "react-toastify";
import * as types from "./types";

export const initEmployee = () => {
  return (dispatch, getState) => {
    const { isLoggedIn } = getState().app;
    if (isLoggedIn) {
      dispatch(getEmployeeListRequest());
    }
  };
};

// Get Employee List
export const getEmployeeListRequest = () => ({
  type: types.GET_EMPLOYEE_LIST_REQUEST,
});
export const getEmployeeListSuccess = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.GET_EMPLOYEE_LIST_SUCCESS,
      payload: { employees: { rows: [], count: 0 } },
    });
    setTimeout(
      () =>
        dispatch({
          type: types.GET_EMPLOYEE_LIST_SUCCESS,
          payload: { employees: { rows: data, count: data.length } },
        }),
      100
    );
  };
};
export const getEmployeeListFail = (error) => ({
  type: types.GET_EMPLOYEE_LIST_FAIL,
  payload: { error },
});

// Get Employee
export const getEmployeeRequest = (user_id) => ({
  type: types.GET_EMPLOYEE_REQUEST,
  payload: { user_id },
});
export const getEmployeeSuccess = (data) => {
  return (dispatch, getState) => {
    const { employee } = getState().app;
    dispatch({
      type: types.GET_EMPLOYEE_SUCCESS,
      payload: { employee: { ...employee, ...data } },
    });
  };
};
export const getEmployeeFail = (error) => ({
  type: types.GET_EMPLOYEE_FAIL,
  payload: { error },
});

// Update Employee
export const updateEmployeeRequest = (user_id, params) => ({
  type: types.UPDATE_EMPLOYEE_REQUEST,
  payload: { user_id, params },
});
export const updateEmployeeSuccess = (message) => {
  return (dispatch) => {
    toast.success(message);
    dispatch({
      type: types.UPDATE_EMPLOYEE_SUCCESS,
    });
    dispatch(getEmployeeListRequest());
  };
};
export const updateEmployeeFail = (error) => {
  return (dispatch) => {
    toast.error(error);
    dispatch({
      type: types.UPDATE_EMPLOYEE_FAIL,
      payload: { error },
    });
  };
};

// add Employee
export const addEmployeeRequest = (params) => ({
  type: types.ADD_EMPLOYEE_REQUEST,
  payload: params,
});
export const addEmployeeSuccess = (message) => {
  return (dispatch) => {
    toast.success(message);
    dispatch({
      type: types.ADD_EMPLOYEE_SUCCESS,
      payload: { employee: { count: 0, rows: [] } },
    });
    dispatch(getEmployeeListRequest());
  };
};
export const addEmployeeFail = (error) => {
  return (dispatch) => {
    toast.error(error);
    dispatch({
      type: types.ADD_EMPLOYEE_FAIL,
      payload: { error },
    });
  };
};

// Change Password
export const changePasswordRequest = (user_id, params) => ({
  type: types.CHANGE_PASSWORD_REQUEST,
  payload: { user_id, params },
});
export const changePasswordSuccess = (message) => {
  return (dispatch) => {
    toast.success(message);
    dispatch({
      type: types.CHANGE_PASSWORD_SUCCESS,
    });
    dispatch(getEmployeeListRequest());
  };
};
export const changePasswordFail = (error) => {
  return (dispatch) => {
    toast.error(error);
    dispatch({
      type: types.CHANGE_PASSWORD_FAIL,
      payload: { error },
    });
  };
};
