import { put, call, takeLatest } from "redux-saga/effects";
import * as types from "../actions/types";
import * as AuthService from "../services/AuthService";
import * as AuthAction from "../actions/AuthAction";

export default function* sagas() {
  function* login(action) {
    try {
      const params = action.payload || {};
      const res = yield call(AuthService.login, params);
      if (res) {
        if (res.role === "Admin" || res.role === "Supervisor")
          yield put(AuthAction.loginSuccess(res));
        else yield put(AuthAction.loginFail(res?.message || "Access denied"));
      } else {
        yield put(AuthAction.loginFail(res?.message || "Invalid request"));
      }
    } catch (error) {
      console.log(error);
      yield put(AuthAction.loginFail(error?.response?.data?.message || "Invalid request"));
    }
  }
  yield takeLatest(types.LOGIN_REQUEST, login);
}
