import * as types from "../actions/types";

const initialState = {
  loader: false,
  tools: { count: 0, rows: [] },
  tool: null,
  toolFiles: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.INIT_APP:
      return { ...state, loader: false };
    case types.GET_TOOL_LIST_REQUEST:
    case types.GET_TOOL_REQUEST:
    case types.GET_SPARE_TOOL_REQUEST:
    case types.GET_TOOL_FILES_REQUEST:
      return { ...state, ...(action.payload || {}) };
    case types.ADD_TOOL_REQUEST:
    case types.UPDATE_TOOL_REQUEST:
    case types.REMOVE_SPARE_TOOL_REQUEST:
    case types.UPLOAD_TOOL_FILE_REQUEST:
      return { ...state, loader: true };
    case types.ADD_TOOL_SUCCESS:
    case types.ADD_TOOL_FAIL:
    case types.UPDATE_TOOL_SUCCESS:
    case types.UPDATE_TOOL_FAIL:
    case types.REMOVE_SPARE_TOOL_SUCCESS:
    case types.REMOVE_SPARE_TOOL_FAIL:
    case types.UPLOAD_TOOL_FILE_SUCCESS:
    case types.UPLOAD_TOOL_FILE_FAIL:
      return { ...state, ...(action.payload || {}), loader: false };
    case types.GET_TOOL_LIST_SUCCESS:
    case types.GET_TOOL_LIST_FAIL:
    case types.GET_SPARE_TOOL_SUCCESS:
    case types.GET_SPARE_TOOL_FAIL:
    case types.GET_TOOL_SUCCESS:
    case types.GET_TOOL_FAIL:
    case types.GET_TOOL_FILES_SUCCESS:
    case types.GET_TOOL_FILES_FAIL:
      return { ...state, ...(action.payload || {}) };
    case types.LOGOUT_REQUEST:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
