import * as types from "../actions/types";

const initialState = {
  employee: null,
  employees: { count: 0, rows: [] },
  loader: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.INIT_APP:
      return { ...state, loader: false };
    case types.GET_EMPLOYEE_REQUEST:
    case types.GET_EMPLOYEE_LIST_REQUEST:
    case types.ADD_EMPLOYEE_REQUEST:
    case types.UPDATE_EMPLOYEE_REQUEST:
    case types.CHANGE_PASSWORD_REQUEST:
      return { ...state, loader: true };
    case types.GET_EMPLOYEE_SUCCESS:
    case types.GET_EMPLOYEE_FAIL:
    case types.GET_EMPLOYEE_LIST_SUCCESS:
    case types.GET_EMPLOYEE_LIST_FAIL:
    case types.ADD_EMPLOYEE_SUCCESS:
    case types.ADD_EMPLOYEE_FAIL:
    case types.UPDATE_EMPLOYEE_SUCCESS:
    case types.UPDATE_EMPLOYEE_FAIL:
    case types.CHANGE_PASSWORD_SUCCESS:
    case types.CHANGE_PASSWORD_FAIL:
      return { ...state, ...(action.payload || {}), loader: false };
    case types.LOGOUT_REQUEST:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
