import { put, call, takeLatest } from "redux-saga/effects";
import * as types from "../actions/types";
import * as SparesService from "../services/SparesService";
import * as SpareAction from "../actions/SpareAction";
import { handleException } from "./Utils";

export default function* sagas() {
  function* getSparesList() {
    try {
      const res = yield call(SparesService.getSparesList);
      if (res) {
        yield put(SpareAction.getSparesListSuccess(res));
      } else {
        yield put(SpareAction.getSparesListFail(res?.message || "Invalid request"));
      }
    } catch (error) {
      console.log(error);
      if (yield handleException(error)) {
        // To-do
      } else {
        yield put(
          SpareAction.getSparesListFail(
            error?.response?.data?.message || "Something went wrong, please try again."
          )
        );
      }
    }
  }
  function* addSpare(action) {
    try {
      const params = action.payload || {};
      const res = yield call(SparesService.addSpare, params);
      if (res) {
        yield put(SpareAction.addSpareSuccess("Spare Details is added successfully"));
      } else {
        yield put(SpareAction.addSpareFail(res?.message || "Invalid request"));
      }
    } catch (error) {
      console.log(error);
      if (yield handleException(error)) {
        // To-do
      } else {
        yield put(
          SpareAction.addSpareFail(
            error?.response?.data?.message || "Something went wrong, please try again."
          )
        );
      }
    }
  }
  function* addExistingSpare(action) {
    try {
      const { tool_id, spare_ids } = action.payload || {};
      const promises = spare_ids.map((spare_id) =>
        SparesService.addExistingSpare({ tool_id, spare_id })
      );
      // const res = yield call(SparesService.addExistingSpare, { tool_id });
      const res = yield Promise.all(promises);
      if (res) {
        yield put(SpareAction.addExistingSpareSuccess(tool_id, "Spares added successfully"));
      } else {
        yield put(SpareAction.addExistingSpareFail(res?.message || "Invalid request"));
      }
    } catch (error) {
      console.log(error);
      if (yield handleException(error)) {
        // To-do
      } else {
        yield put(
          SpareAction.addExistingSpareFail(
            error?.response?.data?.message || "Something went wrong, please try again."
          )
        );
      }
    }
  }
  function* updateSpare(action) {
    try {
      const { id, params } = action.payload || {};
      const res = yield call(SparesService.updateSpare, id, params);
      if (res) {
        yield put(SpareAction.updateSpareSuccess("Spare Details updated successfully"));
      } else {
        yield put(SpareAction.updateSpareFail(res?.message || "Invalid request"));
      }
    } catch (error) {
      console.log(error);
      if (yield handleException(error)) {
        // To-do
      } else {
        yield put(
          SpareAction.updateSpareFail(
            error?.response?.data?.message || "Something went wrong, please try again."
          )
        );
      }
    }
  }
  function* removeSpare(action) {
    try {
      const { id } = action.payload || {};
      const res = yield call(SparesService.removeSpare, id);
      if (res) {
        yield put(SpareAction.removeSpareSuccess("Spare Deleted successfully"));
      } else {
        yield put(SpareAction.removeSpareFail(res?.message || "Invalid request"));
      }
    } catch (error) {
      console.log(error);
      if (yield handleException(error)) {
        // To-do
      } else {
        yield put(
          SpareAction.removeSpareFail(
            error?.response?.data?.message || "Something went wrong, please try again."
          )
        );
      }
    }
  }
  function* addSpareDrawing(action) {
    try {
      const { id, params } = action.payload || {};
      const res = yield call(SparesService.addSpareDrawing, id, params);
      if (res) {
        yield put(SpareAction.addSpareDrawingSuccess("Spare Drawing added successfully"));
      } else {
        yield put(SpareAction.addSpareDrawingFail(res?.message || "Invalid request"));
      }
    } catch (error) {
      console.log(error);
      if (yield handleException(error)) {
        // To-do
      } else {
        yield put(
          SpareAction.addSpareDrawingFail(
            error?.response?.data?.message || "Something went wrong, please try again."
          )
        );
      }
    }
  }
  function* addSparePhoto(action) {
    try {
      const { id, params } = action.payload || {};
      const res = yield call(SparesService.addSparePhoto, id, params);
      if (res) {
        yield put(SpareAction.addSparePhotoSuccess("Spare Photo added successfully"));
      } else {
        yield put(SpareAction.addSparePhotoFail(res?.message || "Invalid request"));
      }
    } catch (error) {
      console.log(error);
      if (yield handleException(error)) {
        // To-do
      } else {
        yield put(
          SpareAction.addSparePhotoFail(
            error?.response?.data?.message || "Something went wrong, please try again."
          )
        );
      }
    }
  }

  yield takeLatest(types.GET_SPARE_LIST_REQUEST, getSparesList);
  yield takeLatest(types.ADD_SPARE_REQUEST, addSpare);
  yield takeLatest(types.ADD_EXISTING_SPARE_REQUEST, addExistingSpare);
  yield takeLatest(types.UPDATE_SPARE_REQUEST, updateSpare);
  yield takeLatest(types.REMOVE_SPARE_REQUEST, removeSpare);
  yield takeLatest(types.ADD_SPARE_DRAWING_REQUEST, addSpareDrawing);
  yield takeLatest(types.ADD_SPARE_PHOTO_REQUEST, addSparePhoto);
}
