// Init action types
export const INIT_APP = "INIT_APP";

// Auth
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

// Employee
export const GET_EMPLOYEE_REQUEST = "GET_EMPLOYEE_REQUEST";
export const GET_EMPLOYEE_SUCCESS = "GET_EMPLOYEE_SUCCESS";
export const GET_EMPLOYEE_FAIL = "GET_EMPLOYEE_FAIL";

export const ADD_EMPLOYEE_REQUEST = "ADD_EMPLOYEE_REQUEST";
export const ADD_EMPLOYEE_SUCCESS = "ADD_EMPLOYEE_SUCCESS";
export const ADD_EMPLOYEE_FAIL = "ADD_EMPLOYEE_FAIL";

export const UPDATE_EMPLOYEE_REQUEST = "UPDATE_EMPLOYEE_REQUEST";
export const UPDATE_EMPLOYEE_SUCCESS = "UPDATE_EMPLOYEE_SUCCESS";
export const UPDATE_EMPLOYEE_FAIL = "UPDATE_EMPLOYEE_FAIL";

export const GET_EMPLOYEE_LIST_REQUEST = "GET_EMPLOYEE_LIST_REQUEST";
export const GET_EMPLOYEE_LIST_SUCCESS = "GET_EMPLOYEE_LIST_SUCCESS";
export const GET_EMPLOYEE_LIST_FAIL = "GET_EMPLOYEE_LIST_FAIL";

// Tools
export const GET_TOOL_LIST_REQUEST = "GET_TOOL_LIST_REQUEST";
export const GET_TOOL_LIST_SUCCESS = "GET_TOOL_LIST_SUCCESS";
export const GET_TOOL_LIST_FAIL = "GET_TOOL_LIST_FAIL";

export const GET_TOOL_REQUEST = "GET_TOOL_REQUEST";
export const GET_TOOL_SUCCESS = "GET_TOOL_SUCCESS";
export const GET_TOOL_FAIL = "GET_TOOL_FAIL";

export const ADD_TOOL_REQUEST = "ADD_TOOL_REQUEST";
export const ADD_TOOL_SUCCESS = "ADD_TOOL_SUCCESS";
export const ADD_TOOL_FAIL = "ADD_TOOL_FAIL";

export const UPDATE_TOOL_REQUEST = "UPDATE_TOOL_REQUEST";
export const UPDATE_TOOL_SUCCESS = "UPDATE_TOOL_SUCCESS";
export const UPDATE_TOOL_FAIL = "UPDATE_TOOL_FAIL";

export const GET_SPARE_TOOL_REQUEST = "GET_SPARE_TOOL_REQUEST";
export const GET_SPARE_TOOL_SUCCESS = "GET_SPARE_TOOL_SUCCESS";
export const GET_SPARE_TOOL_FAIL = "GET_SPARE_TOOL_FAIL";

export const REMOVE_SPARE_TOOL_REQUEST = "REMOVE_SPARE_TOOL_REQUEST";
export const REMOVE_SPARE_TOOL_SUCCESS = "REMOVE_SPARE_TOOL_SUCCESS";
export const REMOVE_SPARE_TOOL_FAIL = "REMOVE_SPARE_TOOL_FAIL";

export const GET_TOOL_FILES_REQUEST = "GET_TOOL_FILES_REQUEST";
export const GET_TOOL_FILES_SUCCESS = "GET_TOOL_FILES_SUCCESS";
export const GET_TOOL_FILES_FAIL = "GET_TOOL_FILES_FAIL";

export const UPLOAD_TOOL_FILE_REQUEST = "UPLOAD_TOOL_FILE_REQUEST";
export const UPLOAD_TOOL_FILE_SUCCESS = "UPLOAD_TOOL_FILE_SUCCESS";
export const UPLOAD_TOOL_FILE_FAIL = "UPLOAD_TOOL_FILE_FAIL";

// Spares
export const GET_SPARE_LIST_REQUEST = "GET_SPARE_LIST_REQUEST";
export const GET_SPARE_LIST_SUCCESS = "GET_SPARE_LIST_SUCCESS";
export const GET_SPARE_LIST_FAIL = "GET_SPARE_LIST_FAIL";

export const ADD_SPARE_REQUEST = "ADD_SPARE_REQUEST";
export const ADD_SPARE_SUCCESS = "ADD_SPARE_SUCCESS";
export const ADD_SPARE_FAIL = "ADD_SPARE_FAIL";

export const ADD_EXISTING_SPARE_REQUEST = "ADD_EXISTING_SPARE_REQUEST";
export const ADD_EXISTING_SPARE_SUCCESS = "ADD_EXISTING_SPARE_SUCCESS";
export const ADD_EXISTING_SPARE_FAIL = "ADD_EXISTING_SPARE_FAIL";

export const GET_SPARE_REQUEST = "GET_SPARE_REQUEST";
export const GET_SPARE_SUCCESS = "GET_SPARE_SUCCESS";
export const GET_SPARE_FAIL = "GET_SPARE_FAIL";

export const UPDATE_SPARE_REQUEST = "UPDATE_SPARE_REQUEST";
export const UPDATE_SPARE_SUCCESS = "UPDATE_SPARE_SUCCESS";
export const UPDATE_SPARE_FAIL = "UPDATE_SPARE_FAIL";

export const REMOVE_SPARE_REQUEST = "REMOVE_SPARE_REQUEST";
export const REMOVE_SPARE_SUCCESS = "REMOVE_SPARE_SUCCESS";
export const REMOVE_SPARE_FAIL = "REMOVE_SPARE_FAIL";

export const ADD_SPARE_DRAWING_REQUEST = "ADD_SPARE_DRAWING_REQUEST";
export const ADD_SPARE_DRAWING_SUCCESS = "ADD_SPARE_DRAWING_SUCCESS";
export const ADD_SPARE_DRAWING_FAIL = "ADD_SPARE_DRAWING_FAIL";

export const ADD_SPARE_PHOTO_REQUEST = "ADD_SPARE_PHOTO_REQUEST";
export const ADD_SPARE_PHOTO_SUCCESS = "ADD_SPARE_PHOTO_SUCCESS";
export const ADD_SPARE_PHOTO_FAIL = "ADD_SPARE_PHOTO_FAIL";