import { initEmployee } from "./UserAction";
import * as types from "./types";
import * as API from "../services/Api";
import { initTools } from "./ToolsAction";
import { initSpares } from "./SpareAction";

// Init App
export const initApp = () => {
  return (dispatch, getState) => {
    const { isLoggedIn, token, userId } = getState().app;
    if (isLoggedIn) {
      API.setHeaderAuthorization(token);
      dispatch(initEmployee());
      dispatch(getUserInfoRequest(userId));
      dispatch(initTools());
      dispatch(initSpares());
    }
    dispatch({ type: types.INIT_APP });
  };
};

// Get Employee
export const getUserInfoRequest = (user_id) => ({
  type: types.GET_USER_REQUEST,
  payload: { user_id },
});
export const getUserInfoSuccess = (user) => {
  return (dispatch) => {
    dispatch({
      type: types.GET_USER_SUCCESS,
      payload: { user },
    });
  };
};
export const getUserInfoFail = (error) => ({
  type: types.GET_USER_FAIL,
  payload: { error },
});
