import { toast } from "react-toastify";
import * as types from "./types";

export const initTools = () => {
  return (dispatch, getState) => {
    const { isLoggedIn } = getState().app;
    if (isLoggedIn) {
      dispatch(getToolsListRequest());
    }
  };
};

// Get Tools List
export const getToolsListRequest = () => ({
  type: types.GET_TOOL_LIST_REQUEST,
});
export const getToolsListSuccess = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.GET_TOOL_LIST_SUCCESS,
      payload: { tools: { rows: data, count: data.length } },
    });
  };
};
export const getToolsListFail = (error) => ({
  type: types.GET_TOOL_LIST_FAIL,
  payload: { error },
});

// Get Tool
export const getToolRequest = (id) => {
  return (dispatch) => {
    dispatch({
      type: types.GET_TOOL_REQUEST,
      payload: { id },
    });
  };
};
export const getToolSuccess = (tool) => ({
  type: types.GET_TOOL_SUCCESS,
  payload: { tool },
});
export const getToolFail = (error) => ({
  type: types.GET_TOOL_FAIL,
  payload: { error },
});

// add Tool
export const addToolRequest = (params) => ({
  type: types.ADD_TOOL_REQUEST,
  payload: params,
});
export const addToolSuccess = (data, message) => {
  return (dispatch) => {
    toast.success(message);
    dispatch({
      type: types.ADD_TOOL_SUCCESS,
      payload: { tools: { count: 0, rows: [] }, tool: data },
    });
    dispatch(getToolsListRequest());
  };
};
export const addToolFail = (error) => {
  return (dispatch) => {
    toast.error(error);
    dispatch({
      type: types.ADD_TOOL_FAIL,
      payload: { error },
    });
  };
};

// Update Tool
export const updateToolRequest = (id, params) => ({
  type: types.UPDATE_TOOL_REQUEST,
  payload: { id, params },
});
export const updateToolSuccess = (data, message) => {
  return (dispatch) => {
    toast.success(message);
    dispatch({
      type: types.UPDATE_TOOL_SUCCESS,
      payload: { tools: { count: 0, rows: [] }, tool: data },
    });
    dispatch(getToolsListRequest());
  };
};
export const updateToolFail = (error) => {
  return (dispatch) => {
    toast.error(error);
    dispatch({
      type: types.UPDATE_TOOL_FAIL,
      payload: { error },
    });
  };
};

// Get Spare tool
export const getSpareToolRequest = (id) => {
  return (dispatch) => {
    dispatch({
      type: types.GET_SPARE_TOOL_REQUEST,
      payload: { id },
    });
  };
};
export const getSpareToolSuccess = (spare_list) => ({
  type: types.GET_SPARE_TOOL_SUCCESS,
  payload: { spare_list },
});
export const getSpareToolFail = (error) => ({
  type: types.GET_SPARE_TOOL_FAIL,
  payload: { error },
});

// Remove Spare tool
export const removeSpareToolRequest = (tool_id, spare_id) => ({
  type: types.REMOVE_SPARE_TOOL_REQUEST,
  payload: { tool_id, spare_id },
});
export const removeSpareToolSuccess = (tool_id, message) => {
  return (dispatch) => {
    toast.success(message);
    dispatch({
      type: types.REMOVE_SPARE_TOOL_SUCCESS,
    });
    dispatch(getToolRequest(tool_id));
  };
};
export const removeSpareToolFail = (error) => {
  return (dispatch) => {
    toast.error(error);
    dispatch({
      type: types.REMOVE_SPARE_TOOL_FAIL,
      payload: { error },
    });
  };
};

// Get Tool Files
export const getToolFilesRequest = (id) => {
  return (dispatch) => {
    dispatch({
      type: types.GET_TOOL_FILES_REQUEST,
      payload: { id },
    });
  };
};
export const getToolFilesSuccess = (toolFiles) => ({
  type: types.GET_TOOL_FILES_SUCCESS,
  payload: { toolFiles },
});
export const getToolFilesFail = (error) => ({
  type: types.GET_TOOL_FILES_FAIL,
  payload: { error },
});

// Upload Tool File
export const uploadToolFileRequest = (id, type, params) => ({
  type: types.UPLOAD_TOOL_FILE_REQUEST,
  payload: { id, type, params },
});
export const uploadToolFileSuccess = (id, message) => {
  return (dispatch) => {
    toast.success(message);
    dispatch({
      type: types.UPLOAD_TOOL_FILE_SUCCESS,
    });
    dispatch(getToolFilesRequest(id));
  };
};
export const uploadToolFileFail = (error) => {
  return (dispatch) => {
    toast.error(error);
    dispatch({
      type: types.UPLOAD_TOOL_FILE_FAIL,
      payload: { error },
    });
  };
};
