import { put, call, takeLatest } from "redux-saga/effects";
import * as types from "../actions/types";
import * as ToolsService from "../services/ToolsService";
import * as ToolsAction from "../actions/ToolsAction";
import { handleException } from "./Utils";

export default function* sagas() {
  function* getToolsList() {
    try {
      const res = yield call(ToolsService.getToolsList);
      if (res) {
        yield put(ToolsAction.getToolsListSuccess(res));
      } else {
        yield put(ToolsAction.getToolsListFail(res?.message || "Invalid request"));
      }
    } catch (error) {
      console.log(error);
      if (yield handleException(error)) {
        // To-do
      } else {
        yield put(
          ToolsAction.getToolsListFail(
            error?.response?.data?.message || "Something went wrong, please try again."
          )
        );
      }
    }
  }
  function* getTool(action) {
    try {
      const { id } = action.payload;
      const res = yield call(ToolsService.getTool, id);
      if (res) {
        yield put(ToolsAction.getToolSuccess(res));
      } else {
        yield put(
          ToolsAction.getToolFail(res?.message || "Something went wrong, please try again.")
        );
      }
    } catch (error) {
      console.log(error);
      if (yield handleException(error)) {
        // To-do
      } else {
        yield put(
          ToolsAction.getToolFail(
            error?.response?.data?.message || "Something went wrong, please try again."
          )
        );
      }
    }
  }
  function* addTool(action) {
    try {
      const params = action.payload || {};
      const res = yield call(ToolsService.addTool, params);
      if (res) {
        yield put(ToolsAction.addToolSuccess(res, "Tool Details is added successfully"));
      } else {
        yield put(ToolsAction.addToolFail(res?.message || "Invalid request"));
      }
    } catch (error) {
      console.log(error);
      if (yield handleException(error)) {
        // To-do
      } else {
        yield put(
          ToolsAction.addToolFail(
            error?.response?.data?.message || "Something went wrong, please try again."
          )
        );
      }
    }
  }
  function* updateTool(action) {
    try {
      const { id, params } = action.payload || {};
      const res = yield call(ToolsService.updateTool, id, params);
      if (res) {
        yield put(ToolsAction.updateToolSuccess(res, "Tool Details is updated successfully"));
      } else {
        yield put(ToolsAction.updateToolFail(res?.message || "Invalid request"));
      }
    } catch (error) {
      console.log(error);
      if (yield handleException(error)) {
        // To-do
      } else {
        yield put(
          ToolsAction.updateToolFail(
            error?.response?.data?.message || "Something went wrong, please try again."
          )
        );
      }
    }
  }
  function* getSpareTool(action) {
    try {
      const { id } = action.payload;
      const res = yield call(ToolsService.getSpareTool, id);
      if (res) {
        yield put(ToolsAction.getSpareToolSuccess(res));
      } else {
        yield put(
          ToolsAction.getSpareToolFail(res?.message || "Something went wrong, please try again.")
        );
      }
    } catch (error) {
      console.log(error);
      if (yield handleException(error)) {
        // To-do
      } else {
        yield put(
          ToolsAction.getSpareToolFail(
            error?.response?.data?.message || "Something went wrong, please try again."
          )
        );
      }
    }
  }
  function* removeSpareTool(action) {
    try {
      const { tool_id, spare_id } = action.payload;
      const res = yield call(ToolsService.removeSpareTool, tool_id, spare_id );
      if (res) {
        yield put(ToolsAction.removeSpareToolSuccess(tool_id, "Spare is removed successfully"));
      } else {
        yield put(
          ToolsAction.removeSpareToolFail(res?.message || "Something went wrong, please try again.")
        );
      }
    } catch (error) {
      console.log(error);
      if (yield handleException(error)) {
        // To-do
      } else {
        yield put(
          ToolsAction.removeSpareToolFail(
            error?.response?.data?.message || "Something went wrong, please try again."
          )
        );
      }
    }
  }
  function* getToolFiles(action) {
    try {
      const { id } = action.payload;
      const res = yield call(ToolsService.getToolFiles, id);
      if (res) {
        yield put(ToolsAction.getToolFilesSuccess(res));
      } else {
        yield put(
          ToolsAction.getToolFilesFail(res?.message || "Something went wrong, please try again.")
        );
      }
    } catch (error) {
      console.log(error);
      if (yield handleException(error)) {
        // To-do
      } else {
        yield put(
          ToolsAction.getToolFilesFail(
            error?.response?.data?.message || "Something went wrong, please try again."
          )
        );
      }
    }
  }
  function* uploadToolFile(action) {
    try {
      const { id, type, params } = action.payload || {};
      const res = yield call(ToolsService.uploadToolFile, id, type, params);
      if (res) {
        yield put(ToolsAction.uploadToolFileSuccess(id, `Tool ${type} file uploaded successfully`));
      } else {
        yield put(
          ToolsAction.uploadToolFileFail(res?.message || "Something went wrong, please try again.")
        );
      }
    } catch (error) {
      console.log(error);
      if (yield handleException(error)) {
        // To-do
      } else {
        yield put(
          ToolsAction.uploadToolFileFail(
            error?.response?.data?.message || "Something went wrong, please try again."
          )
        );
      }
    }
  }

  yield takeLatest(types.GET_TOOL_LIST_REQUEST, getToolsList);
  yield takeLatest(types.GET_TOOL_REQUEST, getTool);
  yield takeLatest(types.ADD_TOOL_REQUEST, addTool);
  yield takeLatest(types.UPDATE_TOOL_REQUEST, updateTool);
  yield takeLatest(types.GET_SPARE_TOOL_REQUEST, getSpareTool);
  yield takeLatest(types.REMOVE_SPARE_TOOL_REQUEST, removeSpareTool);
  yield takeLatest(types.GET_TOOL_FILES_REQUEST, getToolFiles);
  yield takeLatest(types.UPLOAD_TOOL_FILE_REQUEST, uploadToolFile);
}
