import { put } from "@redux-saga/core/effects";
import { toast } from "react-toastify";
import * as AuthAction from "../actions/AuthAction";
import HttpStatusCode from "../../constants/HttpStatusCode";
import Axios from "axios";
import * as API from "../services/Api";
export function* handleException(error) {
  // console.log(error, error?.response);
  if (Axios.isCancel(error)) {
    // To-do
    console.log("Request Cancel!");
  } else if (
    // @ts-ignore
    error?.response?.status === HttpStatusCode.UNAUTHORIZED ||
    // @ts-ignore
    error?.response?.status === HttpStatusCode.FORBIDDEN
  ) {
    API.cancelAllRequest();
    yield put(AuthAction.logoutRequest());
    toast.error("Your session has expired. Please re-login.");
  } else {
    return false;
  }
  return true;
}
