import * as API from "./Api";

export const getSparesList = () => {
  return API.get("/spare");
};
export const addSpare = (params) => {
  return API.post("/spare/add", params);
};
export const addExistingSpare = (params) => {
  return API.post("/spare/addExisting", params);
};
export const getSpare = (spare_id) => {
  return API.get(`/spare/${spare_id}`);
};
export const updateSpare = (spare_id, params) => {
  return API.put(`/spare/${spare_id}`, params);
};
export const removeSpare = (spare_id) => {
  return API.remove(`/spare/${spare_id}`);
};
export const addSpareDrawing = (spare_id, params) => {
  return API.post(`/spare/upload/drawing/${spare_id}`, params);
};
export const addSparePhoto = (spare_id, params) => {
  return API.post(`/spare/upload/photo/${spare_id}`, params);
};
export const downloadSpareFile = (spare_id, filetype) => {
  return API.get(`/spare/download/${spare_id}/${filetype}`);
};
