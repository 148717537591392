import React from "react";
import "./style.scss";
function Loader({ show }) {
  if (!show) return null;
  return (
    <div className="loading">
      <div className="load-wrapper">
        <div className="load-box">L</div>
        <div className="load-box">O</div>
        <div className="load-box">A</div>
        <div className="load-box">D</div>
        <div className="load-box">I</div>
        <div className="load-box">N</div>
        <div className="load-box">G</div>
      </div>
    </div>
  );
}

export default Loader;
