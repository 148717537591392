import React from "react";
import "./Layout.scss";
import Header from "./Header/Header";
import { ReactComponent as dashboardIcon } from "../assets/Logo/dashboard.svg";
import { ReactComponent as employeeIcon } from "../assets/Logo/employee.svg";
import { ReactComponent as taskIcon } from "../assets/Logo/task.svg";
import { ReactComponent as toolsIcon } from "../assets/Logo/tools.svg";
import { ReactComponent as sparesIcon } from "../assets/Logo/spares.svg";
import { ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const menus = [
  { icon: dashboardIcon, title: "Dashboard", url: "/" },
  { icon: dashboardIcon, title: "Employee directory" },
  {
    icon: employeeIcon,
    title: "Employee",
    url: "/employee",
    module: "employee",
  },
  { icon: taskIcon, title: "Task", url: "/task", module: "task" },
  { icon: taskIcon, title: "Tools Management" },
  { icon: toolsIcon, title: "Tools", url: "/tool", module: "tool" },
  { icon: sparesIcon, title: "Spares", url: "/spare", module: "spares" },
];
const Layout = ({ Outlet }) => {
  const navigate = useNavigate();
  const [base_url, module] = window.location.pathname.split("/");
  // console.log(module);
  return (
    <div className="container-fluid container-width">
      <Header />
      <div className="d-flex app-layout">
        <div className="sidebar">
          <ListGroup>
            {menus.map((menu, idx) => (
              <ListGroup.Item
                key={idx}
                active={
                  menu.url === window.location.pathname ||
                  module === menu.module
                }
                onClick={() => navigate(menu.url)}
              >
                <div className="logo">
                  <menu.icon />
                </div>
                <div className="title">{menu.title}</div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
        <div className="layout-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
