import React, { useState } from "react";
import "./Header.scss";
import * as Icon from "react-bootstrap-icons";
import logo from "../../assets/Logo/logo.png";
import profile from "../../assets/Logo/profile.jpg";
import { Button, Form, Nav, NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutRequest } from "../../redux/actions/AuthAction";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.app);
  const homePage = () => [navigate("/")];
  const [hindi, setHindi] = useState(false);
  return (
    <div className="header">
      <div className="navbar d-flex align-items-center">
        <div className="logo" onClick={homePage}>
          <img src={logo} alt="" />
        </div>
        <div className="navbar-menu d-flex justify-content-between w-100">
          <Form className="d-flex search-bar">
            <Form.Control
              type="search"
              placeholder="Search"
              className="search-input"
              aria-label="Search"
            />
            <Button className="search-icon">
              <Icon.Search />
            </Button>
          </Form>
          <div className="right">
            <Nav>
              <div className={`language ${hindi ? "grey" : "blue"} `}>English</div>
              <Form.Check type="switch" id="custom-switch" onClick={() => setHindi(!hindi)} />
              <div className={`language ${hindi ? "green" : "grey"} `}>हिंदी</div>
              <Nav.Link className="notification">
                <Icon.BellFill />
              </Nav.Link>
              <NavDropdown
                className="profile-dropdown"
                title=<div className="nav-profile-icon">
                  <Icon.PersonCircle />
                </div>
                id="collasible-nav-dropdown"
              >
                <div className="profile">
                  <div className="profile-img">
                    <Icon.PersonCircle />
                  </div>
                  <div className="profile-name">{user?.username}</div>
                  <div className="profile-status">{user?.role}</div>
                  <Button className="view-profile-btn">View Profile</Button>
                  <Button className="log-out-btn" onClick={() => dispatch(logoutRequest())}>
                    Log out
                  </Button>
                </div>
              </NavDropdown>
            </Nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
