import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Routes, Route, Outlet, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { initApp } from "./redux/actions/AppAction";
import Layout from "./components/Layout";
import Loader from "./components/Loader";

const DashboardScreen = React.lazy(() => import("./screens/Dashboard/Dashboard"));
const EmployeeScreen = React.lazy(() => import("./screens/Employee/EmployeeScreen"));
const EmployeeTaskSheetScreen = React.lazy(() =>
  import("./screens/Employee/components/EmployeeTaskSheet")
);
const TaskScreen = React.lazy(() => import("./screens/Task/TaskScreen"));
const TaskDetailScreen = React.lazy(() => import("./screens/Task/components/TaskDetail"));
const ToolsScreen = React.lazy(() => import("./screens/Tools/ToolsScreen"));
const AddToolsScreen = React.lazy(() => import("./screens/Tools/components/AddTool"));
const AddDocumentsScreen = React.lazy(() => import("./screens/Tools/components/AddDocument"));
const AddSpareScreen = React.lazy(() => import("./screens/Tools/components/AddSpare"));
const ToolsDetailScreen = React.lazy(() => import("./screens/Tools/components/ToolsDetail"));
const ToolHistoryScreen = React.lazy(() => import("./screens/Tools/History/ToolHistoryScreen"));
const ToolUnloadScreen = React.lazy(() => import("./screens/Tools/Unload/ToolUnload"));
const ToolMaintenanceScreen = React.lazy(() =>
  import("./screens/Tools/Maintenance/ToolMaintenance")
);
const SpareScreen = React.lazy(() => import("./screens/Spare/SpareScreen"));
const LoginScreen = React.lazy(() => import("./screens/Auth/LoginScreen"));
const NotFoundPage = React.lazy(() => import("./screens/NotFoundPage"));

const AppLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.app);
  useEffect(() => {
    if (location.pathname !== "/login" && !isLoggedIn) {
      navigate("/login");
    }
  }, [location, isLoggedIn, navigate]);
  return <Layout Outlet={Outlet} />;
};
const AuthLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.app);
  useEffect(() => {
    if (location.pathname === "/login" && isLoggedIn) {
      navigate("/");
    }
  }, [location, isLoggedIn, navigate]);
  return <Outlet />;
};

const App = () => {
  const dispatch = useDispatch();
  const [init, setInit] = useState(false);
  useEffect(() => {
    if (!init) {
      setInit(true);
      dispatch(initApp());
    }
  }, [dispatch, init]);
  return (
    <Suspense fallback={<Loader show={true} />}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AppLayout />}>
            <Route exact path="/" element={<DashboardScreen />} />
            <Route exact path="/employee" element={<EmployeeScreen />} />
            <Route exact path="/employee/task-sheet" element={<EmployeeTaskSheetScreen />} />
            <Route exact path="/task" element={<TaskScreen />} />
            <Route exact path="/task/detail" element={<TaskDetailScreen />} />
            <Route exact path="/tool" element={<ToolsScreen />} />
            <Route exact path="/tool/:tool_id" element={<ToolsDetailScreen />} />
            <Route exact path="/tool/add" element={<AddToolsScreen />} />
            <Route exact path="/tool/edit/:tool_id" element={<AddToolsScreen />} />
            <Route exact path="/tool/:tool_id/document" element={<AddDocumentsScreen />} />
            <Route exact path="/tool/:tool_id/spare" element={<AddSpareScreen />} />
            <Route exact path="/tool/history" element={<ToolHistoryScreen />} />
            <Route exact path="/tools/unload" element={<ToolUnloadScreen />} />
            <Route exact path="/tools/maintenance" element={<ToolMaintenanceScreen />} />
            <Route exact path="/spare" element={<SpareScreen />} />
          </Route>
          <Route path="/" element={<AuthLayout />}>
            <Route exact path="*" element={<NotFoundPage />} />
            <Route exact path="/login" element={<LoginScreen />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </Suspense>
  );
};

export default App;
