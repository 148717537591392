import { toast } from "react-toastify";
import * as types from "./types";
import { getToolRequest } from "./ToolsAction";

export const initSpares = () => {
  return (dispatch, getState) => {
    const { isLoggedIn } = getState().app;
    if (isLoggedIn) {
      dispatch(getSparesListRequest());
    }
  };
};

// Get Spares List
export const getSparesListRequest = () => ({
  type: types.GET_SPARE_LIST_REQUEST,
});
export const getSparesListSuccess = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.GET_SPARE_LIST_SUCCESS,
      payload: { spares: { rows: data, count: data.length } },
    });
  };
};
export const getSparesListFail = (error) => ({
  type: types.GET_SPARE_LIST_FAIL,
  payload: { error },
});

// add Spare
export const addSpareRequest = (params) => ({
  type: types.ADD_SPARE_REQUEST,
  payload: params,
});
export const addSpareSuccess = (message) => {
  return (dispatch) => {
    toast.success(message);
    dispatch({
      type: types.ADD_SPARE_SUCCESS,
      payload: { spares: { count: 0, rows: [] } },
    });
    dispatch(getSparesListRequest());
  };
};
export const addSpareFail = (error) => {
  return (dispatch) => {
    toast.error(error);
    dispatch({
      type: types.ADD_SPARE_FAIL,
      payload: { error },
    });
  };
};

// add Existing Spare
export const addExistingSpareRequest = (params) => ({
  type: types.ADD_EXISTING_SPARE_REQUEST,
  payload: params,
});
export const addExistingSpareSuccess = (tool_id, message) => {
  return (dispatch) => {
    toast.success(message);
    dispatch({
      type: types.ADD_EXISTING_SPARE_SUCCESS,
    });
    dispatch(getToolRequest(tool_id));
  };
};
export const addExistingSpareFail = (error) => {
  return (dispatch) => {
    toast.error(error);
    dispatch({
      type: types.ADD_EXISTING_SPARE_FAIL,
      payload: { error },
    });
  };
};

// Get Spare
export const getSpareRequest = (id) => {
  return (dispatch) => {
    dispatch({
      type: types.GET_SPARE_REQUEST,
      payload: { id },
    });
  };
};
export const getSpareSuccess = (spare) => ({
  type: types.GET_SPARE_SUCCESS,
  payload: { spare },
});
export const getSpareFail = (error) => ({
  type: types.GET_SPARE_FAIL,
  payload: { error },
});

// Update Spare
export const updateSpareRequest = (id, params) => {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_SPARE_REQUEST,
      payload: { id, params },
    });
  };
};
export const updateSpareSuccess = (message) => {
  return (dispatch) => {
    toast.success(message);
    dispatch({
      type: types.UPDATE_SPARE_SUCCESS,
    });
    dispatch(getSparesListRequest());
  };
};
export const updateSpareFail = (error) => ({
  type: types.UPDATE_SPARE_FAIL,
  payload: { error },
});

// Remove Spare
export const removeSpareRequest = (id) => {
  return (dispatch) => {
    dispatch({
      type: types.REMOVE_SPARE_REQUEST,
      payload: { id },
    });
  };
};
export const removeSpareSuccess = (message) => {
  return (dispatch) => {
    toast.success(message);
    dispatch({
      type: types.REMOVE_SPARE_SUCCESS,
    });
    dispatch(getSparesListRequest());
  };
};
export const removeSpareFail = (error) => {
  return (dispatch) => {
    toast.error(error);
    dispatch({
      type: types.REMOVE_SPARE_FAIL,
      payload: { error },
    });
  };
};

// add Spare Drawing
export const addSpareDrawingRequest = (id, params) => ({
  type: types.ADD_SPARE_DRAWING_REQUEST,
  payload: { id, params },
});
export const addSpareDrawingSuccess = (message) => {
  return (dispatch) => {
    toast.success(message);
    dispatch({
      type: types.ADD_SPARE_DRAWING_SUCCESS,
      payload: { spares: { count: 0, rows: [] } },
    });
    dispatch(getSparesListRequest());
  };
};
export const addSpareDrawingFail = (error) => {
  return (dispatch) => {
    toast.error(error);
    dispatch({
      type: types.ADD_SPARE_DRAWING_FAIL,
      payload: { error },
    });
  };
};

// add Spare Photo
export const addSparePhotoRequest = (id, params) => ({
  type: types.ADD_SPARE_PHOTO_REQUEST,
  payload: { id, params },
});
export const addSparePhotoSuccess = (message) => {
  return (dispatch) => {
    toast.success(message);
    dispatch({
      type: types.ADD_SPARE_PHOTO_SUCCESS,
      payload: { spares: { count: 0, rows: [] } },
    });
    dispatch(getSparesListRequest());
  };
};
export const addSparePhotoFail = (error) => {
  return (dispatch) => {
    toast.error(error);
    dispatch({
      type: types.ADD_SPARE_PHOTO_FAIL,
      payload: { error },
    });
  };
};
