import * as types from "../actions/types";

const initialState = {
  loader: false,
  spares: { count: 0, rows: [] },
  spare: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.INIT_APP:
      return { ...state, loader: false };
    case types.GET_SPARE_LIST_REQUEST:
    case types.GET_SPARE_REQUEST:
      return { ...state, ...(action.payload || {}) };
    case types.ADD_SPARE_REQUEST:
    case types.ADD_EXISTING_SPARE_REQUEST:
    case types.UPDATE_SPARE_REQUEST:
    case types.REMOVE_SPARE_REQUEST:
    case types.ADD_SPARE_DRAWING_REQUEST:
    case types.ADD_SPARE_PHOTO_REQUEST:
      return { ...state, loader: true };
    case types.ADD_SPARE_SUCCESS:
    case types.ADD_SPARE_FAIL:
    case types.ADD_EXISTING_SPARE_SUCCESS:
    case types.ADD_EXISTING_SPARE_FAIL:
    case types.UPDATE_SPARE_SUCCESS:
    case types.UPDATE_SPARE_FAIL:
    case types.REMOVE_SPARE_SUCCESS:
    case types.REMOVE_SPARE_FAIL:
    case types.ADD_SPARE_DRAWING_SUCCESS:
    case types.ADD_SPARE_DRAWING_FAIL:
    case types.ADD_SPARE_PHOTO_SUCCESS:
    case types.ADD_SPARE_PHOTO_FAIL:
      return { ...state, ...(action.payload || {}), loader: false };
    case types.GET_SPARE_LIST_SUCCESS:
    case types.GET_SPARE_LIST_FAIL:
    case types.GET_SPARE_SUCCESS:
    case types.GET_SPARE_FAIL:
      return { ...state, ...(action.payload || {}) };
    case types.LOGOUT_REQUEST:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
