import * as API from "./Api";

export const getToolsList = () => {
  return API.get("/tool");
};
export const getTool = (id) => {
  return API.get(`/tool/${id}`);
};
export const addTool = (params) => {
  return API.post("/tool/add", params);
};
export const updateTool = (id, params) => {
  return API.put(`/tool/${id}`, params);
};
export const getSpareTool = (id) => {
  return API.get(`/tool/sparelist/${id}`);
};
export const removeSpareTool = (tool_id, spare_id) => {
  return API.put(`/tool/sparelist/${tool_id}`, { spare_id });
};
export const getToolFiles = (id) => {
  return API.get(`/tool/files/${id}`);
};
export const uploadToolFile = (id, type, params) => {
  return API.post(`/tool/files/${id}/${type}`, params);
};
export const downloadToolFile = (id, type) => {
  return API.get(`/tool/files/${id}/${type}`);
};
